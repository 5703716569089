import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    walletAddress:'',
    avaxPrice:{},
    claimableAmount:0,
    investorData:{},
    contractData:{},
    nodeData:[],
}

export const WalletSlice = createSlice({
    name:'wallet',
    initialState,
    reducers:{
        setWalletAddress(state,action){
            state.walletAddress = action?.payload;
        },
        setAvaxPriceData(state,action){
            state.avaxPrice = action.payload;
        },
        setClaimableAmount(state,action){
            state.claimableAmount = action.payload;
        },
        setInvestorData(state,action){
            state.investorData = action.payload;
        },
        setContractData(state,action){
            state.contractData = action.payload;
        },
        setNodeData(state,action){
            state.nodeData = action.payload;
        },
        pushNodeData(state,action){
            state.nodeData.push(action.payload);
        }
    },
});

export const { 
    setWalletAddress,
    setAvaxPriceData,
    setClaimableAmount,
    setInvestorData,
    setContractData,
    setNodeData,
    pushNodeData,
} = WalletSlice.actions

export default WalletSlice.reducer