import Dashboard from '../views/Dashboard';
import MyNodes from '../views/MyNodes';

let routes = [
	{
		path: '/',
		component: Dashboard,
		layout: 'main',
	},
	{
		path: '/my-nodes',
		component: MyNodes,
		layout: 'main',
	},
];
export default routes;
