import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { configEnv } from './configEnv';
import {store as web3Store} from '../store';
import { pushNodeData, setAvaxPriceData, setClaimableAmount, setContractData, setInvestorData, setNodeData, setWalletAddress } from '../store/walletSlice';
import { tokenPriceAVAX } from './helpers';

export let web3 = new Web3(configEnv.AVAX_RPC);

export const connectWallet = async () => {
	try {
		const state = web3Store.getState();
		const address = state?.web3Reducer?.userAddress;
		if (!address) {
			console.log('Connecting to wallet');
			// /*
			const providerOptions = {
				walletconnect: {
					package: WalletConnectProvider,
					options: {
						bridge: 'https://bridge.walletconnect.org',
						chainId: configEnv.AVAX_CHAINID, //137 for mainnet
						// network: configEnv.AVAX_NETWORK_ID, //matic for mainnet
						rpc: {
							43113: configEnv.AVAX_RPC,
							// 43114: configEnv.AVAX_RPC,
						},
					},
				},
			};

			console.log('Provider is', providerOptions);

			const web3Modal = new Web3Modal({
				// network: configEnv.AVAX_NETWORK_ID,
				cacheProvider: false, // optional
				providerOptions, // required
				disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
			});

			console.log('Web3Modal instance is', web3Modal);
			const provider = await web3Modal.connect();

			provider.on('accountsChanged', async accounts => {
				console.log('Accounts', accounts);
				web3Store.dispatch(setWalletAddress(accounts[0]));
				await getGenericData();
				getOwnedNodes(accounts[0]);
				// await getUserData();
				// await getUserDeposits();
			});

			web3 = new Web3(provider);

			console.log('Web3 instance is', web3);

			const chainid = await web3.eth.getChainId();

			console.log(chainid);
            
			if (chainid !== configEnv.AVAX_CHAINID) {
				// notification("error", `Please connect to ${configEnv.AVAX_NETWORK_NAME}`);
				alert(`Please connect to ${configEnv.AVAX_NETWORK_NAME}`);
				return;
			}
			const accounts = await web3.eth.getAccounts();
			console.log('Acount is', accounts[0]);

			web3Store.dispatch(setWalletAddress(accounts[0]));
			await getGenericData();
			getOwnedNodes(accounts[0]);
			// await getUserData();
			// await getUserDeposits();
		} else {
			console.log('Already connected');
		}
	} catch (err) {
		console.log(err);
		alert(err.message);
	}
};


export const getAvaxPrice = async ()=>{
	try{
		const avaxPrice = await tokenPriceAVAX();

		web3Store.dispatch(setAvaxPriceData(avaxPrice))
	}
	catch(err){
		console.log(err);
		throw new Error(err?.message)
	}
}

export const getRewardAmount = async()=>{
	try{
		const state = web3Store.getState();

		const contract = new web3.eth.Contract(configEnv?.CONTRACT_ABI,configEnv?.CONTRACT_ADDRESS);

		const claimableAmountInWei = await contract.methods.getClaimableAmount(state?.wallet?.walletAddress).call();

		const claimableAmountInEth = web3.utils.fromWei(claimableAmountInWei,"ether");

		web3Store.dispatch(setClaimableAmount(parseFloat(claimableAmountInEth)));

		return claimableAmountInEth;
	}
	catch(err){
		console.log(err);
		throw new Error(err?.message)
	}
}

export const getInvestorInfo = async()=>{
	try{
		const state = web3Store.getState();

		const contract = new web3.eth.Contract(configEnv?.CONTRACT_ABI,configEnv?.CONTRACT_ADDRESS);

		const data = await contract.methods.investorData(state?.wallet?.walletAddress).call();

		console.log(data);

		data['nodesOwned'] = parseFloat(data['nodesOwned']);
		data['totalInvestmentInGreen'] = parseFloat(web3.utils.fromWei(data['totalInvestmentInGreen'],'ether'));
		data['totalInvestmentInAvax'] = parseFloat(web3.utils.fromWei(data['totalInvestmentInAvax'],'ether'));
		data['claimableAmount'] = parseFloat(web3.utils.fromWei(data['claimableAmount'],'ether'));
		data['totalReward'] = parseFloat(data['totalReward'])/10**8;
		data['lastClaimTime'] = parseFloat(data['lastClaimTime']);
		console.log(data);
		web3Store.dispatch(setInvestorData(data));

		return data;
	}
	catch(err){
		console.log(err);
		throw new Error(err?.message)
	}
}

const getContractData = async()=>{
	try{
		const contract = new web3.eth.Contract(configEnv?.CONTRACT_ABI,configEnv?.CONTRACT_ADDRESS);

		const nodeSold = parseFloat(await contract.methods.nodeSold().call());

		web3Store.dispatch(setContractData({nodeSold}));

		// for(let i=0;i<nodeSold;i++){
		// 	const nodeData = await contract.methods.nodeData(i+1).call();

		// 	web3Store.dispatch(pushNodeData(nodeData))
		// }
	}
	catch(err){
		console.log(err);
	}
}

export const getGenericData = async ()=>{
	try{
			await Promise.all([
				getAvaxPrice(),
				getRewardAmount(),
				getInvestorInfo(),
				getContractData(),
			])
	}
	catch(err){
		console.log(err);
		throw new Error(err?.message);
	}
}

export const buyWithAvax = async(value)=>{
	try{
		const state = web3Store.getState();
		const price = 0.25;
		const totalAmount = price * value;
		const valueInWei = web3.utils.toWei(totalAmount?.toString(),'ether');

		const contract = new web3.eth.Contract(configEnv?.CONTRACT_ABI,configEnv?.CONTRACT_ADDRESS);
		const trx = await contract.methods.buyNodeWithAvax(parseInt(value)).send({
			from:state?.wallet?.walletAddress,
			value:valueInWei,
		})
		console.log(trx);

		return trx;
	}
	catch(err){
		console.log(err);
		throw new Error(err?.message);
	}
}

export const buyWithGreenToken = async(value)=>{
	try{
		const state = web3Store.getState();
		const price = 100;
		const totalAmount = price * value;
		const valueInWei = web3.utils.toWei(totalAmount?.toString(),'ether');

		const greenContract = new web3.eth.Contract(configEnv?.TOKEN_ABI,configEnv?.TOKEN_ADDRESS);

		const approveTrx = await greenContract.methods.approve(configEnv?.CONTRACT_ADDRESS,valueInWei).send({
			from:state?.wallet?.walletAddress,
		});

		console.log(approveTrx);

		const contract = new web3.eth.Contract(configEnv?.CONTRACT_ABI,configEnv?.CONTRACT_ADDRESS);
		const trx = await contract.methods.buyNodeWithGreenToken(parseInt(value)).send({
			from:state?.wallet?.walletAddress,
		})
		console.log(trx);

		return trx;
	}
	catch(err){
		console.log(err);
		throw new Error(err?.message);
	}
}

export const claimAmount = async()=>{
	try{
		const state = web3Store.getState();
		const fee = 0.05;
		const totalAmount = fee * state?.wallet?.investorData?.nodesOwned;
		const valueInWei = web3.utils.toWei(totalAmount?.toString(),'ether');


		const contract = new web3.eth.Contract(configEnv?.CONTRACT_ABI,configEnv?.CONTRACT_ADDRESS);
		const trx = await contract.methods.claimAllAmount().send({
			from:state?.wallet?.walletAddress,
			value:valueInWei,
		})
		console.log(trx);

		return trx;
	}
	catch(err){
		console.log(err);
		throw new Error(err?.message);
	}
}

export const getOwnedNodes = async(walletAddress)=>{
	try{
		const contract = new web3.eth.Contract(configEnv?.CONTRACT_ABI,configEnv?.CONTRACT_ADDRESS);

		const nodesOwned = await contract.methods.getOwnedNodes(walletAddress).call();

		const nodeDataArr = [];
		for await(let nodeId of nodesOwned){
			const nodeData = await contract.methods.nodeData(nodeId).call();

			nodeDataArr.push({...nodeData,nodeID:nodeId});
		}
		web3Store.dispatch(setNodeData(nodeDataArr))
	}
	catch(err){
		console.log(err);
		
	}
}

export const payMaintanenceFee = async(nodeID,months)=>{
	try{
		const state = web3Store.getState();

		const price = 0.10;
		const totalAmount = price * months;
		const valueInWei = web3.utils.toWei(totalAmount?.toString(),'ether');

		const contract = new web3.eth.Contract(configEnv?.CONTRACT_ABI,configEnv?.CONTRACT_ADDRESS);


		const trx = await contract.methods.payMaintanenceFee(nodeID,months).send({
			from:state?.wallet?.walletAddress,
			value:valueInWei,
		})

		console.log(trx);

		return trx;
	}
	catch(err){
		console.log(err);
		throw new Error(err?.message);
	}
}