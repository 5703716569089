import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	Button,
	Input,
	Table,
} from 'reactstrap';
import ArrowLeft from '../assets/img/arrow-left.png';
import ArrowRight from '../assets/img/arrow-right.png';
import Gemicon from '../assets/img/gem-small.png';
import Header from '../components/Header';
import { claimAmount, payMaintanenceFee } from '../utils/web3-helpers';
const tableData = [
	{
		name: 'Green Crypto Capital 5',
		date: '1/31/2022، 9:13:22 AM ',
		rewards: 0,
		status: 'Active',
	},
	{
		name: 'Green Crypto Capital 5',
		date: '1/31/2022، 9:13:22 AM ',
		rewards: 0,
		status: 'Active',
	},
	{
		name: 'Green Crypto Capital 5',
		date: '1/31/2022، 9:13:22 AM ',
		rewards: 50000,
		status: 'Inactive',
	},
	{
		name: 'Green Crypto Capital 5',
		date: '1/31/2022، 9:13:22 AM ',
		rewards: 0,
		status: 'Inactive',
	},
	{
		name: 'Green Crypto Capital 5',
		date: '1/31/2022، 9:13:22 AM ',
		rewards: 0,
		status: 'Active',
	},
	{
		name: 'Green Crypto Capital 5',
		date: '1/31/2022، 9:13:22 AM ',
		rewards: 0,
		status: 'Active',
	},
];
const MyNodes = props => {
	const { investorData, nodeData } = useSelector((state) => state?.wallet);

	const [claimAmountLoader,setClaimAmountLoader] = useState(false);

	const claimAmountHandler = async ()=>{
		try{
			setClaimAmountLoader(true);
			const trx = await claimAmount();
			console.log(trx);

			setClaimAmountLoader(false);
		}
		catch(err){
			setClaimAmountLoader(false);
			console.log(err);
			throw new Error(err?.message);
		}
	}

	const payMaintanenceFeeHandler = async(id)=>{
		try{
			const months = 1;
			const trx = await payMaintanenceFee(id,months);

			return trx;
		}
		catch(err){
			console.log(err);
			alert(err?.message);
		}
	}

	return (
		<>
			<Container className='dashboard__container mb-5'>
				<Header />
				<div className='d-flex align-items-center justify-content-center mt-5'>
					<img
						src={ArrowRight}
						alt='img'
						className='d-none d-md-block'
					/>
					<h2 className='font-props section-heading'>MY NODES</h2>
					<img
						src={ArrowLeft}
						alt='img'
						className='d-none d-md-block'
					/>
				</div>
				<Row className='mt-4'>
					<Col md='6'>
						<Card className='custom-card h-100'>
							<CardBody className='text-center'>
								<h5 className='font-props card-label'>
									My Nodes
								</h5>
								<div className='d-flex justify-content-center mt-4 align-items-center'>
									<h2 className='font-props number-gradient gem-number mr-3'>
										{investorData?.nodesOwned || 0}
									</h2>
								</div>
								<br />
								<span className='font-props card-tagline'>
									Total Average Tax{' '}
									<span className='text-green'>12.50%</span>
								</span>
							</CardBody>
						</Card>
					</Col>
					<Col md='6' className='mt-3 mt-md-0'>
						<Card className='custom-card h-100'>
							<CardBody className='text-center'>
								<h5 className='font-props card-label'>
									Total Claimed
								</h5>
								<div className='d-flex justify-content-center mt-4 align-items-center'>
									<h2 className='font-props number-gradient gem-number mr-3'>
										{investorData?.totalReward || 0.00}
									</h2>
								</div>
								<br />
								<span className='font-props card-tagline'>
									<span className='text-green'>
										View nodes
									</span>{' '}
									that you can claim
								</span>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<div className='mt-5 filter__nodes d-block d-md-flex'>
					<div className='d-flex'>
						{/* <div className='search-nodes d-flex mr-4'>
							<Input placeholder='Search nodes' />
							<i className='fas fa-search'></i>
						</div>
						<div className='filter-nodes'>
							<Input type='select'>
								<option value=''>Filter By</option>
							</Input>
						</div> */}
					</div>
					<div className='ml-auto mt-3 mt-md-0'>
						<Button className='btn-claim claim-btn-gradient px-5 font-weight-bold' 
						    onClick={claimAmountHandler}
						>
							{claimAmountLoader ? (
								<div class=" spinner-border" role="status">
								<div class="sr-only">Loading...</div>
								</div>
							) : (
							<>Claim All</>
							)}
						</Button>
					</div>
				</div>
				{!nodeData?.length && <div className='nodata-owned'><h3>No Nodes Owned</h3></div>}
				{nodeData?.length &&
				<Table className='mt-5' responsive>
					
					<thead className='table-header'>
						<tr>
							<th>Name</th>
							<th>Last Claimed</th>
							<th>Fee Paid Upto</th>
							<th>Active</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody className='custom-table-body'>
						{
							nodeData?.map((data,idx)=>(
								<tr key={idx}>
									<td className='text-nowrap'>Green Crypto Node# {data?.nodeID}</td>	
									<td className='text-nowrap'>{new Date(parseInt(data?.lastClaimTime)*1000).toLocaleDateString()}</td>	
									<td className='text-nowrap'>{new Date(parseInt(data?.feePaidUpto)*1000).toLocaleDateString()}</td>	
									<td className={`text-nowrap ${(parseInt(data?.feePaidUpto)*1000)>=Date.now()? 'text-green'
													: 'text-dark6'}`}>{(parseInt(data?.feePaidUpto)*1000)>=Date.now()?'Active':'Inactive'}</td>	
									<Button className='btn-restore restore-btn-gradient px-4' disabled={(parseInt(data?.feePaidUpto)*1000)>=Date.now()} onClick={()=>payMaintanenceFeeHandler(data?.nodeID)}>
											Pay Maintence Fee
									</Button>
								</tr>
							))
						}
					</tbody>
				</Table>
}
			</Container>
		</>
	);
};

export default MyNodes;
