export const tokenPriceAVAX = ()=> {
    try{
    let url = "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=avalanche-2&order=market_cap_desc&per_page=100&page=1&sparkline=true";
    return new Promise((resolve) => { 
      var xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = function () {
        try{
          if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
              let priceJson = JSON.parse(xmlHttp.responseText)
            //   priceJson = +priceJson['avalanche-2']['usd'];
              resolve(priceJson[0])
          }
        }catch(err){
          console.log(err);
          resolve(0)
        }
      }
      xmlHttp.open("GET", url, true);
      xmlHttp.send(null);
    })
  }catch(err){
    console.log(err);
    return 0;
  }
  }