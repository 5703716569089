import { useSelector } from "react-redux";
import { connectWallet } from "../utils/web3-helpers";

const Header = () => {
  const { walletAddress } = useSelector((state) => state?.wallet);

  return (
    <div className="d-flex justify-content-between">
      <h2 className="web-title font-props">GREEN CRYPTO CAPITAL</h2>
      {walletAddress ? (
        <button className="btn-connect btn-buy-green green-btn-gradient">
          {walletAddress?.replace(walletAddress?.substring(5, 38), "***")}
        </button>
      ) : (
        <button
          className="btn-connect btn-buy-green green-btn-gradient"
          onClick={connectWallet}
        >
          Connect Wallet
        </button>
      )}
    </div>
  );
};

export default Header;
