import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card, CardBody, Button, Input } from 'reactstrap';
import ArrowLeft from '../assets/img/arrow-left.png';
import ArrowRight from '../assets/img/arrow-right.png';
import CustomChart from '../components/CustomChart';
import Header from '../components/Header';
import { buyWithAvax, buyWithGreenToken, claimAmount } from '../utils/web3-helpers';
const AdminView = () => {
	const {current_price,price_change_percentage_24h}  = useSelector(state=>state?.wallet?.avaxPrice);

  const {claimableAmount,investorData,contractData} = useSelector(state=>state?.wallet)
  const {totalReward} = useSelector(state=>state?.wallet?.investorData)

	const [value,setValue] = useState(0);
	const [buyWithAvaxLoader,setBuyWithAvaxLoader] = useState(false);
	const [buyWithGreenLoader,setBuyWithGreenLoader] = useState(false);
  const [claimAmountLoader,setClaimAmountLoader] = useState(false);

	const buyWithAvaxHandler = async ()=>{
		try{
			setBuyWithAvaxLoader(true);
			const trx = await buyWithAvax(value);
			console.log(trx);

			setBuyWithAvaxLoader(false);
		}
		catch(err){
			setBuyWithAvaxLoader(false);
			console.log(err);
			throw new Error(err?.message);
		}
	}
	
	const buyWithGreenHandler = async ()=>{
		try{
			setBuyWithGreenLoader(true);
			const trx = await buyWithGreenToken(value);
			console.log(trx);

			setBuyWithGreenLoader(false);
		}
		catch(err){
			setBuyWithGreenLoader(false);
			console.log(err);
			throw new Error(err?.message);
		}
	}

  const claimAmountHandler = async ()=>{
		try{
			setClaimAmountLoader(true);
			const trx = await claimAmount();
			console.log(trx);

			setClaimAmountLoader(false);
		}
		catch(err){
			setClaimAmountLoader(false);
			console.log(err);
			throw new Error(err?.message);
		}
	}

	
	
	return (
    <>
      <Container className="dashboard__container mb-5">
        {/* <h2 className='web-title font-props'>GREEN CRYPTO CAPITAL</h2> */}
        <Header />
        <div className="d-flex align-items-center justify-content-center mt-5">
          <img src={ArrowRight} alt="img" />
          <h2 className="font-props section-heading">DASHBOARD</h2>
          <img src={ArrowLeft} alt="img" />
        </div>
        <Row className="mt-4">
          <Col lg="4" md="6">
            <Card className="custom-card h-100">
              <CardBody>
                <h5 className="font-props card-label">Rewards</h5>
                <div className="d-flex align-items-center">
                  <h2 className="font-props number-gradient gem-number mr-3">
                    {totalReward?.toFixed(2) || 0.000}
                  </h2>
                  <div className="gem-img "></div>
                </div>
                <span className="font-props card-tagline">
                  With rewards you can strengthen <br /> your network, claim now
                </span>
                <br />
                <Button
                  className="mt-3 font-props btn-claim claim-btn-gradient"
                  block
                  onClick={claimAmountHandler}
                >
                  {claimAmountLoader ? (
                    <div class=" spinner-border" role="status">
                      <div class="sr-only">Loading...</div>
                    </div>
                  ) : (
                  <>Claim All</>
                  )}
                </Button>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6">
            <Card className="custom-card h-100">
              <CardBody>
                <div className="d-flex">
                  <div>
                    <h5 className="font-props card-label">Green Price</h5>
                    <div className="d-flex align-items-center">
                      <h2 className="font-props number-gradient green-price mr-2">
                        0.000
                      </h2>
                      <span className="font-props down-percent mr-3">
                        -7.66
                      </span>
                    </div>
                  </div>
                  <div className="ml-auto">
                    <h5 className="font-props card-label">AVAX Price</h5>
                    <div className="d-flex align-items-center">
                      <h2 className="font-props number-gradient green-price mr-2">
                        {current_price?.toFixed(3)}
                      </h2>
                      <span
                        className={`font-props ${
                          price_change_percentage_24h >= 0
                            ? "up-percent"
                            : "down-percent"
                        } mr-3`}
                      >
                        {price_change_percentage_24h > 0 && "+"}
                        {price_change_percentage_24h?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
                <Input
                  className="buy_input"
                  type="number"
                  value={value}
                  onChange={(e) => setValue(e?.target?.value)}
                />
                <Button
                  className="font-props btn-buy-green green-btn-gradient"
                  block
				  onClick={buyWithAvaxHandler}
                >
                  {buyWithAvaxLoader ? (
                    <div class=" spinner-border" role="status">
                      <div class="sr-only">Loading...</div>
                    </div>
                  ) : (
                    <>Buy Node With Avax</>
                  )}
                </Button>
				<Button
                  className="font-props btn-buy-green green-btn-gradient"
                  block
				  onClick={buyWithGreenHandler}
                >
                  {buyWithGreenLoader ? (
                    <div class=" spinner-border" role="status">
                      <div class="sr-only">Loading...</div>
                    </div>
                  ) : (
                    <>Buy Noe With Green</>
                  )}
                </Button>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4" md="6">
            <Card className="custom-card h-100">
              <CardBody>
                <div className="d-flex">
                  <div>
                    <h5 className="font-props card-label">My Nodes</h5>
                    <div className="d-flex align-items-center">
                      <h2 className="font-props number-gradient green-price mr-2">
                        {investorData?.nodesOwned || 0}
                      </h2>
                    </div>
                  </div>
                  <div className="ml-auto">
                    <h5 className="font-props card-label">Total Nodes</h5>
                    <div className="d-flex align-items-center">
                      <h2 className="font-props number-gradient green-price mr-2">
                        {contractData?.nodeSold || 0}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="bottom-nodes">
                  <h5 className="font-props card-label">Active Nodes</h5>
                  <div className="d-flex align-items-center">
                    <h2 className="font-props number-gradient gem-number mr-2">
                    {contractData?.nodeSold?.toFixed(3) || 0.000}
                    </h2>
                    <span className="font-props up-percent mr-3">
                      / {contractData?.nodeSold?.toFixed(3) || 0.000}
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="d-flex align-items-center justify-content-center mt-5">
          <img src={ArrowRight} alt="img" />
          <h2 className="font-props section-heading">CREATE NODE</h2>
          <img src={ArrowLeft} alt="img" />
        </div>
        <Row className="mt-4">
          <Col>
            <Card className="create-node-card">
              <CardBody>
                <Row className="align-items-center">
                  <Col md="3">
                    <div className="green-avax-price font-props">
                      <h2>
                        100 <span className="text-green">GREEN</span>
                      </h2>
                      <h2>0.25 AVAX</h2>
                    </div>
                  </Col>
                  <Col md="4">
                    <Button block className="btn-approve approve-gradient">
                      Approve Contract
                    </Button>
                    <Button block className="btn-approve approve-gradient mt-3">
                      Create a Node
                    </Button>
                  </Col>
                  <Col md="5" className="text-center">
                    <div className="green-avax-price font-props">
                      <h2>
                        1.15 <span className="text-green mr-2">GREEN</span>
                        <small>per day</small>
                      </h2>
                      <h2>
                        Max:
                        <span className="mx-2 text-green">100,00</span>
                        <small>nodes</small>
                      </h2>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AdminView;
