import { Col, Container, Row, Nav, NavItem, NavLink } from 'reactstrap';
import DashboardLogo from '../assets/img/Logo.png';
import DashboardSmallLogo from '../assets/img/small-logo.png';
import DashboardIcon from '../assets/img/dashboard-icon-g.png';
import NodesIcon from '../assets/img/node-icon-g.png';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
const Dashboard = props => {
	const history = useHistory();
	return (
		<>
			<Container fluid className='px-0 overflow-hidden'>
				<Row>
					<Col xl='2' sm='3' xs='2' className='pr-0'>
						<div className='dashboard__sidebar h-100'>
							<Nav vertical className='vertical__nav'>
								<img
									src={DashboardLogo}
									alt='logo'
									className='img-fluid dashboard__logo d-none d-sm-block'
								/>
								<img
									src={DashboardSmallLogo}
									alt='logo'
									className='img-fluid dashboard__logo d-block d-sm-none'
								/>
								<NavItem className='first__nav-item'>
									<NavLink
										tag={Link}
										to='/'
										className={`${
											history.location.pathname === '/' &&
											'active__link'
										}`}
									>
										<div className='d-flex align-items-center'>
											<img
												src={DashboardIcon}
												alt='home'
												className='mr-0 mr-md-2'
											/>
											<p className='mb-0 d-none d-sm-block'>
												Dashboard
											</p>
										</div>
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										tag={Link}
										to='/my-nodes'
										className={`${
											history.location.pathname ===
												'/my-nodes' && 'active__link'
										}`}
									>
										{' '}
										<div className='d-flex align-items-center'>
											<img
												src={NodesIcon}
												alt='reward'
												className='mr-0 mr-md-2'
											/>
											<p className='mb-0 d-none d-sm-block'>
												My Nodes
											</p>
										</div>
									</NavLink>
								</NavItem>
							</Nav>
						</div>
					</Col>
					<Col xl='10' sm='9' xs='10' className=' pl-0'>
						<div className='dashboard__content'>
							{props.children}
						</div>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Dashboard;
